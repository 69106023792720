<template>
  <UiPopup
    :model-value="modelValue"
    title="Incoming call finished"
    :description="`You finished a call with the lead. Call duration was ${`${duration.minutes} minutes and ${duration.seconds} seconds`}. The call details and notes were saved in the Lead details page`"
    primary-button-text="Open lead in new tab"
    secondary-button-text="OK"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  />
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  duration: Duration
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const submit = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
